@import "../../styles/mixins";
@import "../../styles/variables";

.cater {
  background: #f8e5c7;
  display: flex;
  justify-content: center;
  height: 500px;

  &_wrapper {
    width: 85%;

    &_h {
      text-align: center;
      margin: 40px 0;
      font-size: 30px;
      color: $col-primary;
    }

    &_cards::-webkit-scrollbar {
      display: none;
    }

    &_cards {
      display: flex;
      justify-content: space-between;
      @include respondMax("lg-tablet") {
        overflow-x: scroll;
      }

      .supermarkets {
        background-image: url("../../assets/images/supermarket.jpeg");
      }
      .manufacturers {
        background-image: url("../../assets/images/manufacturer.jpeg");
      }
      .retailStores {
        background-image: url("../../assets/images/retail.jpeg");
      }
      .importers {
        background-image: url("../../assets/images/importers.jpg");
      }

      &_container {
        max-width: 23%;
        height: 280px;
        background-color: #7b7162;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        // justify-content: center;
        position: relative;
        transition: 0.5;

        @include respondMax("lg-tablet") {
          min-width: 250px;
          margin-right: 20px;
        }

        &_wrapper {
          width: 80%;
          color: $col-white;
          position: relative;
          z-index: 1;
          margin-top: 25%;

          &_h {
            text-align: center;
            font-size: 25px;
            @include respondMax("mobile") {
              font-size: 22px;
            }
          }
          p {
            margin-top: 17px;
            text-align: center;
            font-size: 16px;
            line-height: 22px;
          }
        }
        &_overlay {
          @include overlay(rgba(0, 0, 0, 0.4));
          border-radius: 10px;
        }
      }
    }
  }
}
