@import "../../styles/mixins";
@import "../../styles/variables";

.testimonials {
  background-color: #f8e5c7;
  padding: 20px 50px 60px;
  margin: 60px 0 30px;
  @include respondMax("mobile") {
    padding: 20px 0 60px;
  }

  &_h {
    text-align: center;
    margin: 20px auto;
    color: $col-primary;
    font-size: 35px;
    @include respondMax("mobile") {
      font-size: 25px;
    }
  }

  &_main {
    display: flex;
    justify-content: center;
    &_wrapper {
      width: 70%;
      display: flex;
      justify-content: space-between;
      @include respondMax("lg-tablet") {
        width: 85%;
      }
      @include respondMax("mobile") {
        width: 85%;
        align-items: center;
      }
      &_outer-circle {
        border: 2px solid $col-yellow;
        border-radius: 50%;
        padding: 20px;
        width: 200px;
        height: 200px;
        @include respondMax("mobile") {
          width: 80px;
          height: 80px;
          padding: 15px;
          border: 1px solid $col-yellow;
        }
        &_inner-circle {
          border: 2px solid $col-yellow;
          border-radius: 50%;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          @include respondMax("mobile") {
            border: 1px solid $col-yellow;
          }
          &_logo {
            img {
              width: 80%;
              height: fit-content;
              aspect-ratio: 3/2;
              object-fit: contain;
              @include respondMax("mobile") {
                width: 40px;
              }
            }
          }
        }
      }

      &_left {
        width: 70%;
        font-size: 20px;
        @include respondMax("lg-tablet") {
          width: 60%;
        }
        @include respondMax("mobile") {
          font-size: 15px;
          width: 70%;
        }

        &_customer {
          margin-top: 20px;
          font-weight: 600;
          @include respondMax("mobile") {
            margin-top: 5px;
          }
        }
      }
    }
  }
}
