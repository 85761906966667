@import "../../styles/mixins";
@import "../../styles/variables";

.error-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 0;
    &_image {
        width: 600px;
        height: 450px;
        @include respondMax("mobile") {
            width: 300px;
            height: 280px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    &_h {
        font-size: 65px;
        @include respondMax("tablet") {
            font-size: 30px;
        }
        @include respondMax("mobile") {
            font-size: 25px;
        }
    }
    &_p {
        margin: 20px 0;
        font-size: 22px;
        color: #6b7280;
        @include respondMax("tablet") {
            font-size: 17px;
            width: 85vw;
            text-align: center;
        }
    }
    &_btn {
        background: $col-primary;
        @include center(320px, 60px);
        color: $col-white;
        font-size: 20px;
        font-weight: 700;
        margin: 15px 0;
        border-radius: 3px;
        @include respondMax("tablet") {
            @include center(90vw, 50px);

        }
        
        &:hover {
            background: rgb(27, 129, 102);
        }
    }
}