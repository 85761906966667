@import "../../styles/mixins";
@import "../../styles/variables";

.pricing-card {
  border: 2px solid $col-primary;
  padding: 30px;
  border-radius: 5px;
  height: 576px;
  min-width: 26%;
  margin-right: 20px;
  background: #f7f8f8;
  @include respondMax("lg-tablet") {
    min-width: 45%;
    height: 530px;
  }
  @include respondMax("mobile") {
    min-width: 70%;
    height: 575px;
  }

  &_plan {
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    @include respondMax("mobile") {
      font-size: 16px;
    }
  }
  &_price {
    font-size: 24px;
    color: $col-primary;
    text-align: center;
    margin: 5px auto;
    font-weight: 700;
    @include respondMax("mobile") {
      font-size: 18px;
    }
  }
  .free {
    color: $col-black;
  }
  &_benefit {
    text-align: center;
    font-size: 19px;
    margin: 10px auto;
    // border-top: 1px solid black;
    // border-bottom: 1px solid black;
    @include respondMax("mobile") {
      font-size: 16px;
      margin: 7px auto;
    }
  }
}
