@import "../../styles/mixins";
@import "../../styles/variables";
.companies {
  margin: 50px auto;
  @include respondMax("mobile") {
    margin-top: 30px;
  }
  h2 {
    text-align: center;
    color: $col-black;
    font-size: 26px;
    margin: 30px auto;
    @include respondMax("mobile") {
      font-size: 18px;
      margin: 15px 0;
    }
  }
  &_wrapper {
    width: 50%;
    display: flex;
    justify-content: space-between;
    margin: 10px auto;
    padding-bottom: 20px;
    @include respondMax("mobile") {
      width: 85%;
    }
    &_logo {
      img {
        min-width: fit-content;
        height: 40px;
        @include respondMax("mobile") {
          width: 12%;
          height: 30px;
          aspect-ratio: 3/2;
          object-fit: contain;
        }
      }
    }
  }
}
