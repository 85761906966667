@import "./variables";

* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  overflow-x: hidden;
  color: $col-text;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  overflow: hidden;
  width: 0px;
  height: 0px;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  // Note:Image won't show unless parent container is given width or Height
}
button {
  border: none;
  cursor: pointer;
}

// Styling for the merchant forms
.formWrapper-header {
  &_h {
    font-size: 35px;
    font-weight: 700;
    @include respondMax("mobile") {
      font-size: 30px;
    }
  }

  &_p {
    margin: 10px 0;
    font-size: 18px;
  }
}
.merchant_input,
.agent_input {
  margin: 15px 0;

  label {
    font-size: 16px;
    color: rgba(72, 70, 70, 1);
  }
  input {
    margin-top: 5px;
  }
  .account-name {
    margin: 15px 0;
    color: $col-grey-3;
  }
  &_wrapper {
    position: relative;

    &_icon {
      position: absolute;
      top: 50%;
      right: 10px;
      font-weight: 600;
      font-size: 16px;
      transform: translateY(-10%);
      cursor: pointer;
    }
  }
}
.autoContainer {
  position: absolute;
  border: 1px $col-grey solid;
  background-color: $col-white;
  z-index: 1;
  transform: translateY(7px);
  max-width: 100%;
  max-height: 350px;
  border-radius: 3px;
  overflow-y: auto;

  @include respondMax("mobile") {
    max-height: 290px;
  }

  &_option {
    padding: 5px 10px;
    cursor: pointer;

    &:hover {
      background-color: whitesmoke;
    }
  }
}
input,
select {
  width: 100%;
  border: 1px $col-grey solid;
  height: 45px;
  border-radius: 3px;
  text-indent: 10px;

  &::placeholder {
    color: $col-grey-3;
  }
}
.state-city {
  display: flex;
  justify-content: space-between;

  .merchant_input {
    margin-top: 5px;
    width: 32%;
  }
}
.warning {
  color: #d0312d;
}
.loading {
  cursor: wait;
}

.spinner {
  margin-left: 7px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%, #ffffff);
  -webkit-mask: radial-gradient(
    farthest-side,
    #0000 calc(100% - 3.8px),
    #000 0
  );
  animation: spinner-zp9dbg 1s infinite linear;
}

@keyframes spinner-zp9dbg {
  to {
    transform: rotate(1turn);
  }
}
