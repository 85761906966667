@import "../../styles/mixins";
@import "../../styles/variables";

.footer {
  background: $col-black;
  display: flex;
  justify-content: center;
  color: $col-white;

  li {
    list-style: none;
  }

  &_container {
    width: 85%;
    @include respondMax("lg-tablet") {
      width: 95%;
    }
    @include respondMax("mobile") {
      width: 92%;
    }
    &_logo {
      width: 135px;
      height: 33px;
      margin: 20px 0;

      @include respondMax("mobile") {
        width: 100px;
        height: 25px;
      }
    }

    &_links {
      display: flex;
      justify-content: space-between;
      margin: 40px 0 20px;

      @include respondMax("mobile") {
        flex-direction: column;
      }

      &_socials {
        margin-top: 15px;
        @include respondMax("mobile") {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
        }

        &_link {
          margin: 15px 0;
          display: flex;
          cursor: pointer;
          @include respondMax("mobile") {
            width: 45vw;
          }
          &_text {
            font-size: 19px;
            display: flex;
            align-items: center;
            height: 100%;

            @include respondMax("mobile") {
              height: 25px;
              font-size: 17px;
            }
          }

          &_icon {
            font-size: 25px;
            margin-right: 15px;
          }
        }
      }

      &_mid {
        display: flex;
        width: 45%;
        justify-content: space-between;
        font-size: 19px;
        @include respondMax("lg-tablet") {
          width: 50%;
        }
        @include respondMax("mobile") {
          width: 100%;
          font-size: 16px;
        }

        li {
          margin: 15px 0;
          cursor: pointer;
        }

        &_product {
          &_h {
            font-weight: 600;
          }
        }
        &_afiari {
          &_h {
            font-weight: 600;
          }
        }
      }

      &_operate {
        font-size: 19px;
        @include respondMax("mobile") {
          font-size: 17px;
        }

        li {
          margin: 15px 0;
          cursor: pointer;
        }
        &_h {
          font-weight: 600;
        }
      }
    }
  }
}

// .footer {
//     display: flex;
//     justify-content: center;
//     padding: 80px 0;
//     @include respondMax("mobile") {
//         padding: 70px 0;
//     }

//     &_container {
//         display: flex;
//         width: 65%;
//         justify-content: space-between;
//         @include respondMax("tablet") {
//             flex-direction: column;
//             align-items: center;
//         }
//         @include respondMax("mobile") {
//             width: 75%;
//         }
//         &_p {
//             font-weight: bold;
//             @include respondMax("tablet") {
//                 margin-bottom: 15px;
//             }
//             @include respondMax("mobile") {
//                 text-align: center;
//             }
//         }
//         &_icons {
//             display: flex;

//             &_icon {
//                 font-size: 33px;
//                 margin: 0 8px;
//                 cursor: pointer;
//                 color: $col-primary;

//                 &:hover {
//                     color: rgb(27, 129, 102);
//                 }
//             }
//         }
//     }
// }
