@import "../../styles/mixins";
@import "../../styles/variables";

.pricing {
  padding: 30px 0;
  position: relative;

  .blurry {
    position: absolute;
    width: 95%;
    height: 70%;
    border-radius: 50%;
    background: #dee7e3;
    filter: blur(120px);
    bottom: 0;
    right: 0;
    z-index: -1;
  }
  &_h {
    text-align: center;
    color: $col-primary;
    font-size: 35px;
    @include respondMax("mobile") {
      font-size: 25px;
    }
  }
  &_p {
    text-align: center;
    font-size: 20px;
    @include respondMax("mobile") {
      font-size: 17px;
      margin: 10px 0;
    }
  }
  &_slider {
    margin: 40px 0 50px 5%;
    display: flex;
    justify-content: space-between;
    overflow-x: scroll;
  }
  &_slider::-webkit-scrollbar {
    display: none;
  }
  &_btn {
    background-color: $col-primary;
    color: $col-white;
    padding: 12px 35px;
    border-radius: 13px;
    font-size: 20px;
    cursor: pointer;
    margin: 30px auto;
    display: flex;
    justify-content: center;
    @include respondMax("mobile") {
      font-size: 16px;
    }
    &:hover {
      background: rgb(27, 129, 102);
    }
  }
}
