@import "../../styles/mixins";
@import "../../styles/variables";

.faq {
  display: flex;
  justify-content: center;
  margin: 20px auto 0;
  padding: 40px 0;
  background: $col-primary;
  color: $col-white;
  @include respondMax("mobile") {
    padding: 30px 0;
  }
  &_wrapper {
    width: 80%;
    @include respondMax("mobile") {
      width: 100%;
    }

    &_h {
      text-align: center;
      margin: 30px 0;
      font-size: 35px;
      @include respondMax("mobile") {
        font-size: 24px;
      }
    }
    &_qna {
      hr {
        margin-bottom: 20px;
      }
    }
  }
}
