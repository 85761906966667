@import "../../styles/mixins";
@import "../../styles/variables";

.hero {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  &_wrapper {
    display: flex;
    justify-content: space-between;
    width: 85%;

    @include respondMax("lg-tablet") {
      width: 90%;
    }
    @include respondMax("mobile") {
      flex-direction: column;
      width: 90%;
    }
    &_cta {
      width: 52%;
      padding: 20px 0;
      @include respondMax("mobile") {
        width: 100%;
      }

      h1 {
        font-size: 55px;

        @include respondMax("lg-tablet") {
          font-size: 45px;
        }
        @include respondMax("mobile") {
          font-size: 38px;
        }
        span {
          color: $col-secondary;
        }
      }
      p {
        width: 85%;
        font-size: 20px;
        margin-top: 20px;
        @include respondMax("mobile") {
          width: 95%;
        }
      }
      &_form {
        width: 85%;
        display: flex;
        align-items: center;
        margin-top: 20px;
        @include respondMax("mobile") {
          width: 95%;
        }
        input {
          border-radius: 10px;
          width: 75%;
          @include respondMax("lg-tablet") {
            width: 65%;
          }
        }
        &_btn {
          width: 25%;
          height: 45px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $col-primary;
          color: $col-white;
          font-weight: 700;
          cursor: pointer;
          margin-left: -10px;
          border-radius: 7px;
          @include respondMax("lg-tablet") {
            width: 35%;
            font-weight: 600;
          }
          &:hover {
            background: rgb(27, 129, 102);
          }
        }
      }
    }
    &_pic {
      position: relative;
      @include respondMax("mobile") {
        margin-top: 20px;
      }

      &_floater-one {
        background: $col-white;
        padding: 10px 15px;
        display: flex;
        position: absolute;
        top: -5%;
        left: -30%;
        border-radius: 17px;
        @include respondMax("mobile") {
          padding: 8px 12px;
          left: -5%;
        }

        &_man {
          width: 32px;
          height: 32px;
          margin-right: 10px;
        }

        &_info {
          &_h {
            font-size: 14px;
            margin-bottom: 2px;
          }
          &_p {
            font-size: 12px;
            color: $col-primary;
          }
        }
      }

      &_floater-two {
        background: $col-white;
        padding: 10px 15px;
        display: flex;
        position: absolute;
        bottom: -5%;
        right: -20%;
        border-radius: 20px;
        @include respondMax("lg-tablet") {
          right: -8%;
        }
        @include respondMax("mobile") {
          padding: 8px 12px;
          right: -5%;
        }

        &_man {
          width: 32px;
          height: 32px;
          margin-right: 10px;
        }

        &_info {
          &_h {
            font-size: 14px;
            margin-bottom: 2px;
          }
          &_p {
            font-size: 12px;
            color: $col-primary;
          }
        }
      }

      &_main {
        width: 400px;
        height: 450px;

        @include respondMax("lg-tablet") {
          width: 350px;
          height: 420px;
        }
        @include respondMax("mobile") {
          width: 320px;
          height: 400px;
          margin: 0 auto;
        }
      }
    }
  }
}
