@import "../../styles/mixins";
@import "../../styles/variables";

.navbar {
  @include center(100%, 100px);

  &_wrapper {
    display: flex;
    width: 85%;
    justify-content: space-between;
    font-size: 20px;
    @include respondMax("mobile") {
      width: 90%;
    }

    &_logo {
      width: 135px;
      height: 33px;
      align-items: center;

      @include respondMax("mobile") {
        width: 100px;
        height: 25px;
      }
    }
    .nav-links {
      left: -100%;
    }
    .show-sidebar {
      left: 0;
    }
    &_list {
      display: flex;
      width: 80%;
      justify-content: space-between;
      @include respondMax("tablet") {
        display: block;
        height: 99vh;
        width: 95%;
        padding-top: 40%;
        background: #ffffff;
        position: absolute;
        top: 0;
        transition: 0.5s;
        z-index: 2;
      }

      &_links {
        list-style: none;
        display: flex;
        width: 55%;
        justify-content: space-between;
        align-items: center;
        @include respondMax("tablet") {
          width: 100%;
          display: block;
        }

        li {
          cursor: pointer;
          font-weight: 600;
          @include respondMax("tablet") {
            text-align: center;
            font-size: 23px;
            margin-bottom: 15px;
          }
        }
        .business {
          color: $col-secondary;
          border-bottom: 3px solid $col-secondary;
          text-align: center;
          width: 28%;
          @include respondMax("tablet") {
            border: none;
            width: inherit;
          }
        }
      }

      &_auth {
        display: flex;
        align-items: center;
        @include respondMax("tablet") {
          flex-direction: column;
          width: 100%;
        }

        &_signin {
          margin-right: 25px;
          font-weight: 700;
          cursor: pointer;
          @include respondMax("tablet") {
            margin: 10px 0 15px;
          }
        }
        &_signup {
          background-color: $col-primary;
          color: $col-white;
          padding: 8px 15px;
          font-weight: 700;
          cursor: pointer;
          @include respondMax("tablet") {
            padding: 10px 55px;
            border-radius: 5px;
          }
        }
      }

      //when logged in
      &_details {
        display: flex;
        @include respondMax("tablet") {
          justify-content: center;
        }

        &_pic {
          width: 60px;
          height: 60px;
          margin-right: 5px;
          @include respondMax("tablet") {
            width: 50px;
            height: 50px;
          }
          @include respondMax("mobile") {
            width: 40px;
            height: 40px;
          }
          img {
            border-radius: 50%;
          }
        }
        &_info {
          margin: auto 2px;
          font-size: 18px;
          font-weight: 600;
          @include respondMax("tablet") {
            font-size: 17px;
          }
          @include respondMax("mobile") {
            font-size: 15px;
          }
        }
      }
    }
    &_hamburger {
      display: none;
      @include respondMax("tablet") {
        display: block;
        font-size: 20px;
        cursor: pointer;
        transition: transform 0.1s;
        z-index: 10;
      }
    }
  }
}
