@import "../../styles/mixins";
@import "../../styles/variables";

.successful {
    @include center(100%, 90vh);

    &_wrapper {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include respondMax("mobile") {
            width: 90%;
        }

        &_heading {
            display: flex;
            align-items: center;
            @include respondMax("mobile") {
                flex-direction: column;
            }

            h2 {
                font-size: 45px;
                font-weight: 700;
                color: $col-primary;
                text-align: center;
                @include respondMax("mobile") {
                    font-size: 30px;
                }
            }

            &_pic {
                width: 100px;
                height: 100px;
                @include respondMax("mobile") {
                    width: 50px;
                    height: 50px;
                }
            }
        }
        &_p {
            margin: 20px 0;
            font-size: 25px;
            font-weight: 700;
            text-align: center;
            text-align: center;
            
            @include respondMax("mobile") {
                font-size: 20px;
            }
        }

        &_btn {
            margin: 10px 0;
            border: 1px $col-primary solid;
            background: $col-white;
            color: $col-primary;
            padding: 12px;
            font-size: 15px;
            font-weight: 600;
            border-radius: 10px;
        }
    }

    &_partycap {
        position: absolute;
        width: 40px;
        height: 40px;
        top: 20%;
        right: 40%;
        @include respondMax("mobile") {
            right: 20%;
        }
    }
    &_partyface {
        position: absolute;
        width: 40px;
        height: 40px;
        bottom: 40%;
        right: 38%;

        @include respondMax("tablet") {
            bottom: 45%;
            right: 30%;
        }
        @include respondMax("mobile") {
            width: 25px;
            height: 25px;
            right: 20%;
            bottom: 40%;
        }
    }
}