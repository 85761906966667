@import "../../styles/mixins";
@import "../../styles/variables";

.modal {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  width: 50%;
  border-radius: 5px;
  padding: 30px 0;
  @include respondMax("lg-tablet") {
    width: 65%;
  }
  @include respondMax("mobile") {
    width: 90%;
  }

  h2 {
    text-align: center;
    margin: 10px auto;

    @include respondMax("mobile") {
      font-size: 20px;
    }
  }
}

.modal-form {
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  input {
    border-radius: 10px;
    width: 75%;
    @include respondMax("lg-tablet") {
      width: 65%;
    }
  }
  &_btn {
    width: 25%;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $col-primary;
    color: $col-white;
    font-weight: 700;
    cursor: pointer;
    margin-left: -10px;
    border-radius: 7px;
    @include respondMax("lg-tablet") {
      width: 35%;
      font-weight: 600;
    }
  }
}
