@import "../../styles/mixins";
@import "../../styles/variables";

.future {
  width: 90%;
  margin: 50px auto;

  &_h {
    text-align: center;
    margin: 20px auto;
    color: $col-primary;
    font-size: 35px;
    @include respondMax("mobile") {
      font-size: 25px;
    }
  }
  &_p {
    text-align: center;
    font-size: 21px;
    @include respondMax("mobile") {
      font-size: 17px;
      line-height: 20px;
    }
  }
  &_btn {
    background-color: $col-primary;
    color: $col-white;
    padding: 12px 35px;
    border-radius: 13px;
    font-size: 20px;
    cursor: pointer;
    margin: 30px auto;
    display: flex;
    justify-content: center;
    @include respondMax("mobile") {
      font-size: 18px;
    }
    &:hover {
      background: rgb(27, 129, 102);
    }
  }
  &_vid {
    width: 95%;
    height: 650px;
    margin: 10px auto;
    display: flex;
    justify-content: center;
    @include respondMax("tablet") {
      height: 500px;
      width: 95%;
    }
    @include respondMax("mobile") {
      height: 220px;
      width: 100%;
    }
  }
}
