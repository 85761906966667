@import "../../styles/mixins";
@import "../../styles/variables";

.merchant,
.agent {
  display: flex;
  justify-content: space-between;
  &_image {
    width: 45%;
    height: 100vh;
    position: fixed;
    top: 0;
    bottom: 0;
    @include respondMax("tablet") {
      display: none;
    }
  }

  &_form {
    width: 40%;
    padding: 25px 0;
    position: absolute;
    right: 10%;
    @include respondMax("tablet") {
      width: 50%;
      left: 50%;
      transform: translateX(-50%);
    }
    @include respondMax("mobile") {
      width: 90%;
    }
    &_logo {
      width: 135px;
      height: 33px;

      img {
        object-fit: contain;
      }
    }
    &_stepslevel {
      margin: 50px 0 20px;
    }
    &_btns {
      display: flex;
      width: 100%;
      justify-content: space-between;
      &_back {
        border: 1px $col-primary solid;
        background: $col-white;
        color: $col-primary;
        &:hover {
          color: rgb(27, 129, 102);
          border: 2px rgb(27, 129, 102) solid;
        }
      }
      &_cont {
        background: $col-primary;
        color: $col-white;
        display: flex;
        align-items: center;
        &:hover {
          background: rgb(27, 129, 102);
        }

        padding: 12px 40px;
        font-size: 15px;
        border-radius: 5px;
        margin: 30px 0;
      }
      // button {
      //   padding: 12px 40px;
      //   font-size: 15px;
      //   border-radius: 5px;
      //   margin: 30px 0;
      // }
    }
  }
}

// Styling for the form wrapper
.formWrapper-header {
  margin: 30px 0;
}
.formWrapper-body {
  &_children {
    margin: 10px 0;
  }
}
