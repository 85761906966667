@import "../../styles/mixins";
@import "../../styles/variables";

.offline {
  width: 85%;
  margin: 20px auto;
  @include respondMax("mobile") {
    width: 90%;
  }

  &_h {
    text-align: center;
    margin: 30px auto 50px;
    color: $col-primary;
    font-size: 35px;
    @include respondMax("mobile") {
      font-size: 24px;
      margin-bottom: 20px;
    }
  }
  &_container {
    display: flex;
    justify-content: space-between;
    @include respondMax("lg-tablet") {
      flex-direction: column-reverse;
    }
    &_left {
      width: 50%;
      @include respondMax("lg-tablet") {
        width: 100%;
      }

      hr {
        margin-bottom: 20px;
        @include respondMax("mobile") {
          margin-bottom: 15px;
        }
      }
    }

    &_right {
      width: 530px;
      height: 400px;
      @include respondMax("lg-tablet") {
        width: 85%;
        height: 450px;
        margin: 0 auto;
      }
      @include respondMax("mobile") {
        height: 175px;
        width: 285px;
      }
      img {
        @include respondMax("mobile") {
          object-fit: contain;
        }
      }
    }
  }
}
