@import "../../styles/mixins";
@import "../../styles/variables";

.acc-offline {
  .accordion_content {
    color: $col-text;
    font-weight: 600;
    font-size: 23px;
    @include respondMax("mobile") {
      font-size: 16px;
    }
  }
}
.acc-faq {
  .accordion_top {
    @include respondMax("mobile") {
      width: 90%;
      margin: 0 auto;
    }
  }
  .accordion_content {
    color: $col-white;
    font-size: 24px;
    @include respondMax("mobile") {
      font-size: 17px;
      width: 90%;
      margin: 0 auto;
    }
  }
}
.accordion {
  &_top {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;

    &_h {
      font-size: 23px;
      width: 90%;
      font-weight: 600;
      @include respondMax("mobile") {
        font-size: 17px;
      }
    }
    &_arrow {
      font-weight: 900;
      font-size: 24px;
      @include respondMax("mobile") {
        font-size: 14px;
      }
    }
  }
  &_content {
    position: relative;
    height: 0;
    text-align: justify;
    width: 80%;
    overflow: hidden;
    transition: 0.5s;

    &_btn {
      background-color: $col-primary;
      color: $col-white;
      padding: 10px 15px;
      border-radius: 13px;
      font-size: 20px;
      cursor: pointer;
      margin: 15px 0 5px;
      @include respondMax("mobile") {
        font-size: 15px;
        padding: 8px 17px;
        margin: 10px 0;
      }
    }
  }

  hr {
    margin: 25px 0;
    @include respondMax("mobile") {
      margin: 15px 0;
    }
  }
}
.active.acc-offline {
  .accordion_content {
    height: 150px;
    padding: 20px 0;
    @include respondMax("mobile") {
      height: 120px;
    }
  }
}
.active.acc-faq {
  .accordion_content {
    height: 85px;
    padding: 20px 0;
    @include respondMax("lg-tablet") {
      height: 95px;
    }
    @include respondMax("mobile") {
      height: 97px;
    }
  }
}
