@import "../../styles/mixins";
@import "../../styles/variables";

.landing {
  color: $col-black;
  &_top {
    background-image: url("../../assets/images/hero-background.webp");
    background-color: #111111;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    // color: $col-white;
    position: relative;

    &_wrapper {
      position: relative;
      z-index: 1;
    }

    &_overlay {
      @include overlay(rgba(255, 255, 255, 0.9));
    }
  }
}
