@mixin center($width, $height) {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: $width;
  height: $height;
}

@mixin overlay($bg) {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $bg;
}

// breakpoints
$breakpoints: (
  "mobile": 600px,
  "tablet": 820px,
  "lg-tablet": 920px,
  "sm-desktop": 1125px,
  "desktop": 1366px,
  "lg-desktop": 1600px,
) !default;

@mixin respondMin($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        +"Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin respondMax($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        +"Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
